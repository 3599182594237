<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-domain</v-icon>
          CLIENTES
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn small color="green" dark @click="abrirDialogCliente(null)"
          ><v-icon left>mdi-plus</v-icon>Crear Cliente</v-btn
        >
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-select
            v-model="busTipoEnte"
            class="mt-2"
            :items="tiposente"
            item-text="NOMBRE"
            item-value="TIPO_ENTE_ID"
            clearable
            @change="cargarLista()"
          >
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="busqueda"
            @click:append="cargarLista()"
            @keyup.enter="cargarLista()"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            class="mt-2"
          >
          </v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-2"
          v-model="incInac"
          label="Incluir inactivos"
          @keyup.enter="cargarLista()"
          @change="cargarLista()"
          single-line
        >
        </v-checkbox>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="green"
            title="editar datos de cliente"
            @click="abrirDialogCliente(item)"
          >
            <v-icon left small>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            icon
            color="red"
            title="borrar cliente"
            @click="borrarCliente(item.ENTE_ID)"
          >
            <v-icon left small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOGO CLIENTE  -->
    <v-dialog
      v-model="dialogCliente"
      fullscreen
      scrollable
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-snackbar
          v-model="sbCliente"
          :timeout="timeout"
          :top="true"
          :absolute="true"
          color="#333333"
        >
          {{ mensajeSbCliente }}
          <v-btn color="green" text small @click="sbCliente = false">
            Cerrar
          </v-btn>
        </v-snackbar>
        <v-card height="auto">
          <v-toolbar height="50" fixed flat color="primary" dark>
            <v-btn icon dark @click="dialogCliente = false" title="Cerrar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitulo }}</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical v-model="tabCliente">
            <v-tab>
              <v-icon left>mdi-domain </v-icon>
              Info General
            </v-tab>
            <v-tab class="text-left">
              <v-icon left> mdi-contacts </v-icon>
              Contactos
            </v-tab>

            <v-tab>
              <v-icon left> mdi-currency-usd</v-icon>
              Tarifas
            </v-tab>

            <v-tab :class="datosCliente.TIPO_ENTE_ID != 9 ? 'd-none' : ''">
              <v-icon left> mdi-truck</v-icon>
              Transportista
            </v-tab>

            <v-tab :class="datosCliente.TIPO_ENTE_ID == 9 ? 'd-none' : ''">
              <v-icon left> mdi-flower</v-icon>
              Fincas
            </v-tab>

            <v-tab>
              <v-icon left> mdi-account</v-icon>
              Usuario
            </v-tab>

            <v-tab-item>
              <v-form ref="formCliente">
                <v-card-text flat class="pt-2">
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-2 pb-0" cols="6">
                      <v-select
                        :rules="requiredRule"
                        label="Tipo de cliente"
                        v-model="datosCliente.TIPO_ENTE_ID"
                        item-text="NOMBRE"
                        item-value="TIPO_ENTE_ID"
                        :items="tiposente"
                      ></v-select>
                    </v-col>

                    <v-col class="pt-2 pb-0" cols="6">
                      <v-text-field
                        readonly
                        label="Codigo"
                        v-model="datosCliente.ENTE_ID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-2 pb-0" cols="4">
                      <v-text-field
                        :rules="requiredRule"
                        label="Razón social "
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_NOMBRE"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-2 pb-0" cols="2">
                      <v-text-field
                        label="Marcación"
                        :readonly="lectura"
                        v-model="datosCliente.MARCACION"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-1 pb-1" cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Nombre comercial "
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_NOM_COMER"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="RUC "
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_RUC"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Representante Legal "
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_REPRE"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Teléfono 1"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_TEL"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Teléfono 2"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_TEL2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        :rules="validarEmail1"
                        label="Email principal"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_EMAIL1"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        :rules="validarEmail2"
                        label="Email secundario"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_EMAIL2"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="12">
                      <v-text-field
                        :rules="requiredRule"
                        label="Dirección "
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_DIR"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="4">
                      <v-autocomplete
                        :rules="requiredRule"
                        label="País"
                        clearable
                        :items="paises"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_PAIS"
                        item-text="NOMBRE"
                        item-value="PAIS_CODIGO"
                        @change="setCodigoPais"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="2">
                      <v-text-field
                        readonly
                        label="Codigo pais"
                        v-model="datosCliente.ENTE_PAIS_COD"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        label="Eori / otros codigos"
                        :readonly="lectura"
                        v-model="datosCliente.CNEE_COD"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-0 pb-0" cols="3">
                      <v-text-field
                        label="Ciudad"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_CIUDAD"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="3">
                      <v-autocomplete
                        label="Estado"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_ESTADO"
                        :items="getEstadosPais"
                        item-text="GEO_NAME"
                        item-value="GEO_CODE"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Codigo postal"
                        :readonly="lectura"
                        v-model="datosCliente.ENTE_COD_POSTAL"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-col class="text-center">
                  <v-btn color="primary" small @click="guardarCliente()">
                    <v-icon>mdi-content-save</v-icon> Guardar
                  </v-btn>
                  &nbsp;&nbsp;
                  <v-btn @click="dialogCliente = !dialogCliente" small>
                    <v-icon>mdi-cancel</v-icon> Cancelar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-tab-item>
            <!--CONTACTOS-->
            <v-tab-item v-if="tabContactos">
              <v-card flat class="mx-10">
                <v-toolbar elevation="1">
                  <v-toolbar-title>Contactos</v-toolbar-title>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-btn
                    small
                    color="blue"
                    dark
                    @click="abrirDialogContacto(null)"
                    ><v-icon left>mdi-plus</v-icon>Agregar</v-btn
                  >
                </v-toolbar>
                <v-data-table
                  :headers="headersContactos"
                  :items="listaContactos"
                  :items-per-page="itemsPerPage"
                  :options.sync="options"
                  hide-default-footer
                  :loading="loadingTable"
                  class="elevation-1"
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      icon
                      title="editar contacto"
                      @click="abrirDialogContacto(item)"
                    >
                      <v-icon small color="green">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      title="borrar contacto"
                      @click="borrarContacto(item.CONTACTO_ID)"
                    >
                      <v-icon small color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <!-- tarifas del cliente -->
            <v-tab-item v-if="tabProc == true">
              <v-toolbar flat>
                <v-toolbar-title>Tarifas</v-toolbar-title>
              </v-toolbar>
            </v-tab-item>

            <!-- dias de transporte -->
            <v-tab-item v-if="tabTransp == true">
              <v-radio-group row v-model="transpOpSel">
                <v-radio label="Transportistas" value="1"></v-radio>
                <v-radio label="Dia de transporte" value="2"></v-radio>
              </v-radio-group>
              <v-card v-if="transpOpSel == 1">
                <v-toolbar flat class="mb-4">
                  <v-icon left>mdi-truck</v-icon>
                  <v-toolbar-title>Transportistas</v-toolbar-title>
                  <v-btn
                    class="mx-4"
                    title="Agregar transportista"
                    small
                    color="green"
                    dark
                    :disabled="!verTransp"
                    @click="agregarTransportista"
                    ><v-icon small>mdi-plus</v-icon></v-btn
                  >
                </v-toolbar>
                <v-data-table
                  :headers="headerTranspCliente"
                  :items="transpCliente"
                  hide-deffault-footer
                >
                  <template v-slot:item.opcion="{ item }">
                    <v-btn
                      small
                      :disabled="!verTransp"
                      icon
                      color="green"
                      @click="editarClienteTransportista(item)"
                      ><v-icon small>mdi-pencil</v-icon></v-btn
                    >

                    <v-btn
                      small
                      icon
                      :disabled="!verTransp"
                      color="red"
                      @click="borrarClienteTransportista(item.ID)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card>

              <v-card v-if="transpOpSel == 2">
                <v-toolbar flat class="mb-4">
                  <v-icon left>mdi-truck</v-icon>
                  <v-icon left>mdi-calendar-week</v-icon>
                  <v-toolbar-title
                    >Transportista preferencial según el día de la
                    semana</v-toolbar-title
                  >
                </v-toolbar>
                <v-card class="px-4">
                  <v-form ref="formTransPerf">
                    <v-row>
                      <v-col cols="4" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Lunes"
                          v-model="diaTransp[1]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(1)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(1)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="4" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Martes"
                          v-model="diaTransp[2]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(2)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(2)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Miercoles"
                          v-model="diaTransp[3]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(3)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(3)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Jueves"
                          v-model="diaTransp[4]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(4)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(4)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Viernes"
                          v-model="diaTransp[5]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(5)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(5)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Sábado"
                          v-model="diaTransp[6]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(6)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(6)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-0 my-0">
                        <v-autocomplete
                          :disabled="!verTransp"
                          label="Domingo"
                          v-model="diaTransp[7]"
                          :items="transportistas"
                          item-text="TRANSP_NOMBRE"
                          item-value="TRANSP_ID"
                          @change="guardarTranspCli(7)"
                        >
                          <template slot="append">
                            <v-btn
                              :disabled="!verTransp"
                              small
                              icon
                              title="borrar"
                              @click="borrarDiaTranspCli(7)"
                              ><v-icon>mdi-close</v-icon></v-btn
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
              </v-card>
            </v-tab-item>

            <!-- codigos fincas -->
            <v-tab-item v-if="tabFincas == true">
              <v-card class="pa-4">
                <v-row>
                  <v-col>
                    <v-form ref="formCodigoFinca">
                      <v-toolbar flat
                        ><v-toolbar-title>Fincas</v-toolbar-title>

                        <v-divider class="mx-4" vertical></v-divider>
                        <v-col cols="4" class="mt-4"
                          ><v-autocomplete
                            :rules="requiredRule"
                            v-model="fincaParaAgrear"
                            :items="cboFincas"
                            item-text="ENTE_NOMBRE"
                            item-value="ENTE_ID"
                            label="Fincas"
                          ></v-autocomplete
                        ></v-col>
                        <v-col cols="2" class="mt-3">
                          <v-text-field
                            :rules="requiredRule"
                            v-model="codigoFinca"
                            label="Código"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-6" cols="2">
                          <v-btn
                            small
                            color="green"
                            title="Agregar finca"
                            dark
                            @click="agregarFinca()"
                            ><v-icon>mdi-plus</v-icon></v-btn
                          >
                        </v-col>
                      </v-toolbar>
                    </v-form>
                  </v-col>
                </v-row>

                <v-row justify="center"
                  ><v-col cols="2">
                    <v-text-field
                      v-model="buscarCodigos"
                      label="Buscar"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headerFincas"
                      :items="codigosFincas"
                      :search="buscarCodigos"
                      :items-per-page="itemsPerPageFincas"
                      :options.sync="options"
                      :loading="loadingTable"
                      class="elevation-1"
                    >
                      <template v-slot:item.opcion="{ item }">
                        <v-btn
                          small
                          color="red"
                          icon
                          title="Borrar codigo"
                          @click="borrarFinca(item)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <!-- tab usuario -->
            <v-tab-item v-if="tabUsuario == true">
              <v-form ref="formUsuario">
                <v-card-text flat class="pt-2">
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-2 pb-0" cols="6">
                      <v-text-field
                        label="CODIGO"
                        readonly
                        v-model="datosUsuario.ENTE_ID"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0">
                    <v-col class="pt-2 pb-0" cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Nombre de usuario"
                        v-model="datosUsuario.USER_NAME"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-2 pb-0" cols="6">
                      <v-text-field
                        :rules="passwordRule"
                        label="Contraseña"
                        v-model="datosUsuario.USER_PASS"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-col>
                    <v-btn @click="guardarUsuario()" dark color="green"
                      ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
                    >
                  </v-col>
                </v-card-actions>
              </v-form>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- dialogo contacto -->
    <v-dialog v-model="dialogContacto" max-width="750px" persistent scrollable>
      <v-form ref="formContacto">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-account</v-icon>
              Contacto
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogContacto = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-4">
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="requiredRule"
                  class="mt-0"
                  label="Nombres"
                  v-model="datosContacto.NOMBRE"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ocupación"
                  v-model="datosContacto.OCUPACION"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :rules="validarEmail1Contacto"
                  class="mt-0"
                  label="Email principal"
                  v-model="datosContacto.CORREO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  :rules="validarEmail2Contacto"
                  class="mt-0"
                  label="Email secundario"
                  v-model="datosContacto.CORREO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0 my-0">
                <v-text-field
                  class="mt-0"
                  label="Telefono oficina"
                  v-model="datosContacto.TELEFONO1"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Teléfono casa"
                  v-model="datosContacto.TELEFONO2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Celular"
                  v-model="datosContacto.CELULAR1"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Otro teléfono"
                  v-model="datosContacto.CELULAR2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Dirección"
                  v-model="datosContacto.DIRECCION"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Ciudad"
                  v-model="datosContacto.CIUDAD"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="País"
                  v-model="datosContacto.PAIS"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-textarea
                  style="font-size: 0.85em"
                  rows="2"
                  class="mt-0"
                  label="Observaciones"
                  v-model="datosContacto.OBSERVACION"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-toolbar flat>
            <v-btn @click="guardarContacto()" small color="green" dark
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogContacto = false" small text
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- dialogo transportista -->
    <v-dialog
      v-model="dialogTransportista"
      max-width="650px"
      persistent
      scrollable
    >
      <v-form ref="formTransportista">
        <v-card>
          <v-toolbar flat color="blue" fixed height="40" dark>
            <v-toolbar-title>
              <v-icon class="mx-1">mdi-truck</v-icon>
              Transportista
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogTransportista = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  label="Transportista"
                  v-model="datosTransp.TRANSPORTISTA_ID"
                  :items="transportistas"
                  item-text="TRANSP_NOMBRE"
                  item-value="TRANSP_ID"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Codigo de cuenta"
                  v-model="datosTransp.CODIGO_CUENTA"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Observaciones"
                  v-model="datosTransp.OBSERVACIONES_ENVIO"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-toolbar flat>
            <v-btn @click="guardarTransportista()" small color="green" dark
              ><v-icon left>mdi-content-save</v-icon>Guardar</v-btn
            >
            <v-divider vertical class="px-4"></v-divider>
            <v-btn @click="dialogTransportista = false" small text
              ><v-icon left>mdi-cancel</v-icon>Cancelar</v-btn
            >
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style>
.v-text-field input {
  font-size: 0.8em;
}

.v-text-field .v-label {
  font-size: 0.95em;
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ClientesComponent",

  components: {},
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Cliente", value: "ENTE_NOMBRE" },
      { text: "Marcación", value: "MARCACION" },
      { text: "PAIS", value: "ENTE_PAIS" },
      { text: "Codigo", value: "ENTE_ID" },
    ],

    headersContactos: [
      { text: "Opciones", value: "opcion" },
      { text: "Nombre", value: "NOMBRE" },
      { text: "Ocupacion", value: "OCUPACION" },
      { text: "Correo", value: "CORREO1" },
      { text: "Teléfono", value: "TELEFONO1" },
      { text: "Celular", value: "CELULAR1" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    passwordRule: [
      (v) =>
        (v && v.length >= 6) ||
        "La contraseña debe tener al menos 6 caracteres",
      (v) => !!v || "La contraseña es requerida",
    ],
    emailRule: [
      (v) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "El email es inválido xx",
    ],

    emailRule2: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "El email es inválido xx2",
    ],

    lista: [],
    options: {},
    notifications: false,
    paises: [],
    estadosPais: [],

    busqueda: null,
    busTipoEnte: 3,

    itemsPerPage: 20,
    itemsPerPageFincas: 20,
    pageCount: 1,
    totalItems: 0,
    currentPage: 1,
    tabCliente: 0,
    dialogCliente: false,
    diaTransp: ["", "", "", "", "", "", ""],

    formTitulo: "Cliente",

    incInac: false,
    datosCliente: { ENTE_ID: "", ENTE_EMAIL1: "", TIPO_ENTE_ID: 2 },
    listaContactos: [],

    lectura: false,

    show: false,
    show2: false,

    timeout: 5000,

    dialogContacto: false,
    datosContacto: {},

    sbCliente: false,
    tabAl: true,
    tabProc: true,
    tabContactos: true,
    tabUsuario: true,
    tabTransp: true,
    tabFincas: true,
    verTransp: false,

    mensajeSbCliente: "",
    tiposente: [
      { TIPO_ENTE_ID: 3, NOMBRE: "CLIENTE" },
      { TIPO_ENTE_ID: 7, NOMBRE: "SUB CLIENTE" },
      { TIPO_ENTE_ID: 9, NOMBRE: "CLIENTE FINAL" },
    ],

    headerTranspCliente: [
      { text: "Opciones", value: "opcion" },
      { text: "ID", value: "TRANSP_ID" },
      { text: "Transportista", value: "TRANSP_NOMBRE" },
    ],
    transportistas: [],
    transporte: [],
    transpCliente: [],
    transpOpSel: 1,
    dialogTransportista: false,
    datosTransp: {},
    editarTransp: false,

    headerFincas: [
      { text: "Opciones", value: "opcion" },
      { text: "ID", value: "ID" },
      { text: "Finca", value: "ENTE_NOMBRE" },
      { text: "Codgo", value: "CODIGO" },
    ],
    codigosFincas: [],
    cboFincas: [],
    fincaParaAgrear: "",
    codigoFinca: "",
    buscarCodigos: "",
    datosUsuario: {},
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),
    ...mapGetters("access", [""]),
    ...mapState("access", ["idActionsRol"]),
    validarEmail1() {
      return this.validarEmail(this.datosCliente.ENTE_EMAIL1);
    },
    validarEmail2() {
      return this.validarEmail(this.datosCliente.ENTE_EMAIL2);
    },
    validarEmail1Contacto() {
      return this.validarEmail(this.datosContacto.CORREO1);
    },
    validarEmail2Contacto() {
      return this.validarEmail(this.datosContacto.CORREO2);
    },
    getEstadosPais() {
      let ep = [];

      for (let i = 0; i < this.estadosPais.length; i++) {
        if (this.estadosPais[i].PAIS_CODIGO == this.datosCliente.ENTE_PAIS) {
          ep.push(this.estadosPais[i]);
        }
      }
      return ep;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),

    puedoVerTransp() {
      if (this.idActionsRol.includes(53)) {
        this.verTransp = true;
      } else {
        this.verTransp = false;
      }
      console.log(this.idActionsRol);
      console.log(this.verTransp);
    },
    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }

      if (
        String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        return [true];
      } else {
        return ["Email inválido"];
      }
      /*if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }*/
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-clientes");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          inactivos: this.incInac,
          bus_nombre_cliente: this.busqueda,
          tipo_ente_id: this.busTipoEnte,
        },
      })
        .then((res) => {
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          this.transportistas = res.data.transportistas;
          console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    abrirDialogCliente(item) {
      this.tabCliente = 0;
      this.dialogCliente = true;
      this.tabContactos = true;
      this.tabProc = true;
      this.tabTransp = true;
      this.tabFincas = true;
      this.tabUsuario = true;
      this.fincaParaAgrear = "";
      this.codigoFinca = "";
      if (item == null) {
        this.tabContactos = false;
        this.tabProc = false;
        this.tabUsuario = false;
        this.tabTransp = false;
        this.tabFincas = false;
        this.cargardatosCliente(null);
        this.datosCliente = { ENTE_ID: null, TIPO_ENTE_ID: 3, ACTIVO: 1 };
        return false;
      }
      this.cargardatosCliente(item.ENTE_ID);
    },
    cargardatosCliente(clienteId) {
      this.setLoadingTable(true);
      (this.diaTransp = ["", "", "", "", "", "", ""]),
        this.setUrl("api/cliente");
      this.requestApi({
        method: "GET",
        data: {
          cliente_id: clienteId == null ? "" : clienteId,
        },
      })
        .then((res) => {
          if (clienteId != null) {
            this.datosCliente = res.data.datosCliente;
            this.datosUsuario.ENTE_ID = res.data.datosCliente.ENTE_ID;
            this.datosUsuario.USER_NAME = res.data.datosCliente.USER_NAME;
            this.datosUsuario.USER_PASS = res.data.datosCliente.USER_PASS;

            this.listaContactos = res.data.contactos;
            //this.procesos = res.data.procesos;
            this.transporte = res.data.transporte;
            this.transpCliente = res.data.transp_cliente;
            this.cboFincas = res.data.fincas;
            this.codigosFincas = res.data.codigos_fincas.lista;
            this.setDiasTransporte();
          }
          this.paises = res.data.paises;
          this.estadosPais = res.data.estados_pais;
          this.formTitulo = "Cliente: " + this.datosCliente.ENTE_NOM_COMER;
          return false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    setDiasTransporte() {
      for (let i = 0; i < this.transporte.length; i++) {
        //if(this.transporte[i].DIA_TRANSPORTE)
        this.diaTransp[this.transporte[i].DIA_TRANSPORTE] =
          this.transporte[i].TRANSPORTISTA_ID;
      }
    },
    modificarCliente() {
      this.setUrl("api/modificar-cliente");
      this.requestApi({
        method: "POST",
        data: {
          cliente: this.datosCliente,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.dialogCliente = false;
          this.cargarLista();
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarCliente() {
      if (!this.$refs.formCliente.validate()) {
        return false;
      }
      //console.log(this.datosCliente);
      if (this.datosCliente.ENTE_ID == null) {
        this.crearCliente();
      } else {
        this.modificarCliente();
      }
    },
    crearCliente() {
      this.setUrl("api/crear-cliente");
      //console.log(this.datosCliente);
      this.requestApi({
        method: "POST",
        data: {
          cliente: this.datosCliente,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargarLista();
          this.dialogCliente = false;
        })
        .catch(() => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
              icon: "error",
              title: "Error",
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange() {
      this.cargarLista();
    },
    abrirDialogContacto(item) {
      if (item === null) {
        this.datosContacto = {
          CONTACTO_ID: "",
          TIPO_ENTE_ID: 1,
          CLIENTE_ID: this.datosCliente.ENTE_ID,
        };
        this.dialogContacto = true;
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/contacto-al");
      this.requestApi({
        method: "GET",
        data: {
          contacto_id: item.CONTACTO_ID,
        },
      })
        .then((res) => {
          this.datosContacto = res.data.datosContacto;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearCotacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/crear-contacto-al");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarContacto() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }
      this.dialogContacto = true;
      this.setUrl("api/modificar-contacto-al");
      this.requestApi({
        method: "POST",
        data: {
          contacto: this.datosContacto,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
          this.dialogContacto = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarContacto(contactoId) {
      Vue.swal({
        html: "Está seguro de borrar este contacto  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-contacto-al");
          this.requestApi({
            method: "POST",
            data: {
              contactoId: contactoId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargardatosCliente(this.datosCliente.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarContacto() {
      if (this.datosContacto.CONTACTO_ID != "") {
        this.modificarContacto();
      } else {
        this.crearCotacto();
      }
    },

    //PROCESOS
    abrirDialogProceso(item) {
      if (item === null) {
        this.datosProceso = {
          ID: "",
          MANEJADOR: "",
          CLIENTE_ID: this.datosCliente.ENTE_ID,
          FORMATO: "",
          NOMBRE: "",
          MENSAJE_REMITENTE: "",
          MENSAJE_DESTINATARIO: "",
        };
        this.dialogProceso = true;
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/proceso-al");
      this.requestApi({
        method: "GET",
        data: {
          proceso_id: item.ID,
        },
      })
        .then((res) => {
          this.datosProceso = res.data.datosProceso;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    crearProceso() {
      if (!this.$refs.formProceso.validate()) {
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/crear-proceso-al");
      this.requestApi({
        method: "POST",
        data: {
          proceso: this.datosProceso,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
          this.dialogProceso = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    modificarProceso() {
      if (!this.$refs.formProceso.validate()) {
        return false;
      }
      this.dialogProceso = true;
      this.setUrl("api/modificar-proceso-al");
      this.requestApi({
        method: "POST",
        data: {
          proceso: this.datosProceso,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
          this.dialogProceso = false;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    borrarProceso(procesoId) {
      Vue.swal({
        html: "Está seguro de borrar este dato  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-proceso-al");
          this.requestApi({
            method: "POST",
            data: {
              procesoId: procesoId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargardatosCliente(this.datosCliente.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarProceso() {
      if (this.datosProceso.ID != "") {
        this.modificarProceso();
      } else {
        this.crearProceso();
      }
    },
    setCodigoPais() {
      this.this.datosCliente.ENTE_PAIS_COD = this.datosCliente.ENTE_PAIS;
    },
    borrarCliente(clienteId) {
      Vue.swal({
        html: "Está seguro de borrar esta cliente  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-cliente");
          this.requestApi({
            method: "POST",
            data: {
              clienteId: clienteId,
            },
          })
            .then((res) => {
              this.alertNotification({
                param: {
                  html: res.data.msg,
                  timer: 3000,
                },
              });
              this.cargarLista();
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },
    guardarTranspCli(dia) {
      // if (!this.$refs.formTransPerf.validate()) {
      //   return false;
      // }

      this.setUrl("api/guardar-cliente-transp");
      this.requestApi({
        method: "POST",
        data: {
          TRANSP_ID: this.diaTransp[dia],
          CLIENTE_ID: this.datosCliente.ENTE_ID,
          DIA_TRANSPORTE: dia,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
        })
        .catch(() => {})
        .then(() => {});
    },

    borrarDiaTranspCli(dia) {
      if (confirm("Está seguro de borrar el transportista ?")) {
        this.setUrl("api/borrar-cliente-transp");
        this.requestApi({
          method: "POST",
          data: {
            TRANSP_ID: this.diaTransp[dia],
            CLIENTE_ID: this.datosCliente.ENTE_ID,
            DIA_TRANSPORTE: dia,
          },
        })
          .then((res) => {
            this.alertNotification({
              param: {
                html: res.data.msg,
                timer: 3000,
              },
            });
            this.cargardatosCliente(this.datosCliente.ENTE_ID);
          })
          .catch(() => {})
          .then(() => {});
      }
    },

    agregarTransportista() {
      this.editarTransp = false;
      this.datosTransp.TRANSPORTISTA_ID = "";
      this.datosTransp.OBSERVACIONES_ENVIO = "";
      this.datosTransp.CODIGO_CUENTA = "";
      this.datosTransp.CLIENTE_ID = this.datosCliente.ENTE_ID;
      this.dialogTransportista = true;
    },

    guardarTransportista() {
      if (this.editarTransp == true) {
        this.modificarClienteTransportista();
      } else {
        this.crearTransportista();
      }
    },
    crearTransportista() {
      this.setUrl("api/crear-cliente-transportista");
      this.requestApi({
        method: "POST",
        data: {
          TRANSPORTISTA_ID: this.datosTransp.TRANSPORTISTA_ID,
          CLIENTE_ID: this.datosTransp.CLIENTE_ID,
          CODIGO_CUENTA: this.datosTransp.CODIGO_CUENTA,
          OBSERVACIONES_ENVIO: this.datosTransp.OBSERVACIONES_ENVIO,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
        })
        .catch(() => {})
        .then(() => {});
    },
    borrarClienteTransportista(id) {
      if (confirm("Está seguro de borrar el transportista ?")) {
        this.setUrl("api/borrar-cliente-transportista");
        this.requestApi({
          method: "POST",
          data: {
            ID: id,
          },
        })
          .then((res) => {
            this.alertNotification({
              param: {
                html: res.data.msg,
                timer: 3000,
              },
            });
            this.cargardatosCliente(this.datosCliente.ENTE_ID);
          })
          .catch(() => {})
          .then(() => {});
      }
    },
    editarClienteTransportista(item) {
      this.editarTransp = true;
      this.datosTransp = item;
      // this.datosTransp.TRANSPORTISTA_ID = "";
      // this.datosTransp.OBSERVACIONES_ENVIO = "";
      // this.datosTransp.CODIGO_CUENTA = "";
      // this.datosTransp.CLIENTE_ID = this.datosCliente.ENTE_ID;
      this.dialogTransportista = true;
    },

    modificarClienteTransportista() {
      this.setUrl("api/modificar-cliente-transportista");
      this.requestApi({
        method: "POST",
        data: this.datosTransp,
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
        })
        .catch(() => {})
        .then(() => {});
    },

    agregarFinca() {
      if (this.$refs.formCodigoFinca.validate() == false) {
        return false;
      }
      this.setUrl("api/cliente-agregar-finca");
      this.requestApi({
        method: "POST",
        data: {
          cliente_id: this.datosCliente.ENTE_ID,
          finca_id: this.fincaParaAgrear,
          codigo: this.codigoFinca,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          this.cargardatosCliente(this.datosCliente.ENTE_ID);
        })
        .catch(() => {})
        .then(() => {});
    },

    borrarFinca(item) {
      Vue.swal({
        html: "Está seguro de borrar este código  ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/cliente-borrar-finca");
          this.requestApi({
            method: "POST",
            data: {
              id: item.ID,
            },
          })
            .then((res) => {
              // this.alertNotification({
              //   param: {
              //     html: res.data.msg,
              //     timer: 3000,
              //   },
              // });
              this.cargardatosCliente(this.datosCliente.ENTE_ID);
            })
            .catch(() => {})
            .then(() => {});
        }
      });
    },

    guardarUsuario() {
      if (!this.$refs.formUsuario.validate()) {
        return false;
      }
      this.setUrl("api/guardar-usuario-cliente");
      this.requestApi({
        method: "POST",
        data: {
          datosUsuario: this.datosUsuario,
        },
      })
        .then((res) => {
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 3000,
            },
          });
          //this.cargardatosCliente(this.datosUsuario .ENTE_ID);
        })
        .catch(() => {})
        .then(() => {});
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Clientes");

    if (typeof this.$route.params.clienteId != "undefined") {
      //this.filters[0].v_model = this.$route.params.partyId
      //this.filters[4].v_model = 'PARTY_IN_PROCESS'
      this.abrirDialogCliente({ ENTE_ID: this.$route.params.clienteId });
    }

    this.puedoVerTransp();

    //typeof this.$route.params.clienteId != 'undefined' &&
    //  this.editSupplier(this.$route.params.partyId)
  },
  watch: {
    options: {
      handler() {
        this.cargarLista();
      },
      deep: true,
    },
  },
};
</script>
